import { useCallback, useEffect, useMemo, useState } from "react";
import gamerize from "../../apis/gamerize";
import TabError from "../../components/TabError/TabError";
import Table from "../../components/Table/Table";
import fetchNext from "../../helper/fetchNext";
import Button from "../../components/Button/Button";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { getFormattedDate } from "../../helper/getFormattedDate";
import "./AnalyticsWordsStudied.scss";
import { Link } from "react-router-dom";

const ASCENDING_ORDER = "word_count";
const DESCENDING_ORDER = "-word_count";

const selectOptions = [
  { value: "all", label: "All" },
  { value: "spelling_the_word", label: "Spelling the Word" },
  { value: "writing_the_sentence", label: "Writing the Sentence" },
  { value: "saying_the_word", label: "Saying the Word" },
  { value: "say_the_sentence'", label: "Saying the Sentence" },
];

const WordsStudied = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Username",
        accessor: "username",
        Cell: ({ row }) => (
          <Link to={`student/${row.original.id}`}>{row.original.username}</Link>
        ),
      },
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Word Count",
        accessor: "word_count",
      },
    ],

    []
  );

  const [stats, setStats] = useState([]);
  const [next, setNext] = useState(null);
  const [error, setError] = useState("");

  const [order, setOrder] = useState(DESCENDING_ORDER);
  let currentDate = new Date();
  let now = new Date();
  now.setDate( currentDate.getDate() - 7 );
  
  const [startDateSearch, setStartDateSearch] =  useState(now);
  const [endDateSearch, setEndDateSearch] = useState(currentDate);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const filterLearningAims = ({ value }) => {
    switch (value) {
      case "all":
        return fetchStats("all");
      case "spelling_the_word":
        return fetchStats(1);
      case "writing_the_sentence":
        return fetchStats(2);
      case "saying_the_word":
        return fetchStats(3);
      case "say_the_sentence'":
        return fetchStats(4);
      default:
        return fetchStats("all");
    }
  };

  const handleSearch = () => {
    setError(null);
    const startDateFormatted = getFormattedDate(startDateSearch);
    const currentDate = getFormattedDate(new Date());
    if (currentDate < startDateFormatted) {
      setError({ message: "Error,You cannot select that start date!" });
    }
    if (endDateSearch) {
      const endDateFormatted = getFormattedDate(endDateSearch);
      if (endDateFormatted < startDateFormatted) {
        setError({ message: "Error, Invalid Date Selection!" });
      }
      if (currentDate < endDateFormatted) {
        setError({ message: "Error,You cannot select that end date!" });
      }
      setEndDate(endDateFormatted);
    }
    setStartDate(startDateFormatted);
  };

  const URL = `/analytics/stats/words-studied/?`;

  const fetchStats = useCallback(
    async (learning_aim = "all", limit = 10) => {
      try {
        if (learning_aim === "all") {
          const response = await gamerize.get(
            `${URL}limit=${limit}&ordering=${order}&start=${startDate}&end=${endDate}`
          );
          setStats(response.data.results);
          setNext(response.data.next);
        } else {
          const response = await gamerize.get(
            `${URL}limit=${limit}&ordering=${order}&learning_aim=${learning_aim}&start=${startDate}&end=${endDate}`
          );
          setStats(response.data.results);
          setNext(response.data.next);
        }
      } catch (error) {
        setError({ message: "Error fetching data" });
      }
    },
    [startDate, endDate, order]
  );

  useEffect(() => {
    fetchStats("all", 20);
  }, [fetchStats]);

  return (
    <div className="words-studied-page fl-1 flex-column">
      <div className="flex justify-between">
        <div>
          <h1 className="mh-24 mt-24 mb-24">Number of Words Studied</h1>
          <div className="ml-24">
            <h4>Learning Aim : </h4>
            <div className="mt-8 drop-down-size">
              <Select
                options={selectOptions}
                onChange={filterLearningAims}
                defaultValue={selectOptions[0]}
                className="bundle-list-filter"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="flex mt-24 mr-24">
            <div className="mr-16">
              <h4>Enter Start Date:</h4>
              <DatePicker
                selected={startDateSearch}
                onChange={(date) => setStartDateSearch(date)}
              />
            </div>
            <div className="mr-16">
              <h4>Enter End Date:</h4>
              <DatePicker
                selected={endDateSearch}
                onChange={(date) => setEndDateSearch(date)}
              />
            </div>
            <Button primary label="Search" onClick={handleSearch} />
          </div>
          <div className="flex justify-end">
            <h2 className="mt-32 mr-16 mb-24">Order : </h2>
            <Button
              primary
              className="mr-16 mt-24 mb-4 mb-24 order-select"
              label="Ascending"
              onClick={() => setOrder(ASCENDING_ORDER)}
            />
            <Button
              primary
              className=" mr-24 mt-24 mb-4 mb-24 order-select"
              label="Descending"
              onClick={() => setOrder(DESCENDING_ORDER)}
            />
          </div>
        </div>
      </div>
      {error ? (
        <TabError
          onClick={() => {
            setError("");
            fetchStats(1);
          }}
          message={error.message}
          label="Reload Stats"
        />
      ) : (
        <div id="scrollableContainer" className="list-container fl-1">
          <Table
            columns={columns}
            data={stats}
            update={async () => {
              try {
                const response = await fetchNext(URL, next);
                const { data, nextPage } = response;
                setStats((prevStats) => [...prevStats, ...data]);
                setNext(nextPage);
              } catch (error) {
                setError("Error fetching more data");
              }
            }}
            next={next}
          />
        </div>
      )}
    </div>
  );
};

export default WordsStudied;
