import { Route, Routes } from "react-router-dom";
import SideBar from "../components/SideBar/SideBar";
import Analytics from "../pages/Analytics/Analytics";
import AdventuresReview from "../pages/AnalyticsAdventureReview/AnalyticsAdventureReview";
import AdventuresCompleted from "../pages/AnalyticsAdventureCompleted/AnalyticsAdventureCompleted";
import WordsStudied from "../pages/AnalyticsWordsStudied/AnalyticsWordsStudied";
import NoCheckInStats from "../pages/AnalyticsNoCheckInStats/AnalyticsNoCheckInStats";
import NoTimeSpent from "../pages/StudentAnalytics/NoTimeSpent";
import NoAdventurePlayed from "../pages/StudentAnalytics/NoAdventurePlayed";
import AnalyticsStatsRouter from "./AnalyticsStatsRouter";
import AnalyticsStudent from "../pages/AnalyticsStudent/AnalyticsStudent";
import IncompleteAdvenures from "../pages/AllAssignedAdventures/AllAssignedAdventures";

const AnalyticsRouter = () => {
  const PAGES = [
    { title: "Analytics", link: `/analytics/dashboard` },
    { title: "Login Stats", link: `/analytics/analytics-stats` },
    { title: "Adventures in Review", link: `/analytics/adventures-in-review` },
    {
      title: "Adventure Completed",
      link: `/analytics/adventures-completed`,
    },
    { title: "Words Studied", link: `/analytics/number-of-words-studied` },
    { title: "No CheckIn Stats", link: `/analytics/no-checkin-stats` },
    { title: "No Adventures Played", link: `/analytics/no-adventures-played` },
    { title: "No Time Spent", link: `/analytics/no-time-spent` },
  ];

  return (
    <div className="flex page">
      <SideBar pages={PAGES} />
      <Routes>
        <Route path={`dashboard`} element={<Analytics/>} />
        <Route
          path={`analytics-stats/*`}
          element={<AnalyticsStatsRouter/>}
        />
        <Route
          path={`student/:id`}
          element={<AnalyticsStudent/>}
        />
        <Route
          path={`adventures-in-review`}
          element={<AdventuresReview/>}
        />
        <Route
          path={`adventures-completed`}
          element={<AdventuresCompleted/>}
        />
        <Route
          path={`number-of-words-studied`}
          element={<WordsStudied/>}
        />
        <Route
          path={`no-checkin-stats`}
          element={<NoCheckInStats/>}
        />
        {
          <Route
            path={`no-adventures-played`}
            element={<NoAdventurePlayed/>}
          />
        }
        <Route path={`no-time-spent`} element={<NoTimeSpent/>} />
        <Route
          path={`student/:id/incomplete-adventure`}
          element={<IncompleteAdvenures/>}
        />
      </Routes>
    </div>
  );
};

export default AnalyticsRouter;
